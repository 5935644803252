<template>
  <v-data-table
    :headers="headers"
    :items="model"
    sort-by="info.creationDate"
    sort-desc
    :loading="isLoading"
    :search="search"
  >
    <template v-slot:top>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn @click="createUser">
            <v-icon left>mdi-account-plus</v-icon>顧客の追加
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small @click="editUser(item)">mdi-pencil</v-icon>
      <!-- <v-icon small @click="deleteUser(item)">mdi-delete</v-icon> -->
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'users',
  props: {
    model: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    headers: [
      {
        text: '作成日時',
        align: 'start',
        sortable: true,
        value: 'info.creationDate'
      },
      // {
      //   text: 'ID',
      //   align: 'start',
      //   sortable: true,
      //   width: 90,
      //   value: 'profile.userId'
      // },
      {
        text: '顧客代表者名',
        align: 'start',
        sortable: true,
        // width: 120,
        value: 'info.customerCompanyRepName'
      },
      {
        text: '会社名/店舗名',
        align: 'start',
        sortable: true,
        // width: 80,
        value: 'info.customerCompanyName'
      },
      {
        text: 'メールアドレス',
        align: 'start',
        sortable: true,
        value: 'info.customerEmail'
      },
      {
        text: 'プラン',
        align: 'center',
        sortable: true,
        width: 120,
        value: 'info.plan'
      },
      // {
      //   text: '契約',
      //   align: 'center',
      //   sortable: true,
      //   width: 80,
      //   value: 'plans.contract'
      // },
      {
        text: '操作',
        align: 'center',
        sortable: false,
        width: 80,
        value: 'actions'
      }
    ],
    search: ''
  }),
  methods: {
    createUser () {
      this.$emit('createUser')
    },
    editUser (user) {
      this.$emit('editUser', user)
    }
  }
}
</script>
