<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-card>
        <v-toolbar color="primary">
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{title}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn text :disabled="invalid" @click="save">保存</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field v-model="info_.shopNo" label="代理店管理番号/代理店名" />
                </v-col>
                <v-col>
                  <v-text-field v-model="info_.customerNo" label="顧客管理番号" />
                </v-col>
                <v-dialog
                  ref="creationDateDialog"
                  v-model="creationDateDialog"
                  :return-value.sync="info_.creationDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-col>
                      <v-text-field
                        v-model="info_.creationDate"
                        label="申込日"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </v-col>
                  </template>
                  <v-date-picker v-model="info_.creationDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="creationDateDialog = false">Cancel</v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.creationDateDialog.save(info_.creationDate)"
                    >OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-text-field v-model="info_.postalCode" label="郵便番号" />
                </v-col>
                <v-col cols="10">
                  <v-text-field v-model="info_.address" label="住所" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    ref="customerCompanyProvider"
                    name="顧客会社名"
                    :rules="{ required:true }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="info_.customerCompanyName"
                      label="顧客会社名"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="info_.customerCompanyRepName"
                    label="顧客代表者名"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="info_.customerRepDeptName"
                    label="顧客担当部署名"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="info_.customerRepName"
                    label="顧客担当者名"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="info_.customerTelNo"
                    label="顧客連絡先電話番号"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    ref="customerEmailProvider"
                    name="顧客メールアドレス"
                    :rules="{ required:true, email:true, unique:existingEmails }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="info_.customerEmail"
                      label="顧客メールアドレス"
                      :error-messages="errors"
                      :readonly="isEdit"
                    />
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider
                    ref="customerPasswordProvider"
                    name="パスワード"
                    :rules="{ required:passwordRequired, min: 6 }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="info_.customerPassword"
                      label="パスワード"
                      :append-icon="showEye ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showEye ? 'text' : 'password'"
                      @click:append="showEye = !showEye"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="info_.contractPeriod"
                    label="契約期間"
                    type="number"
                    suffix="ヶ月"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col align-self="center" cols="auto">
                  <span class="text-h6">プラン：</span>
                </v-col>
                <v-col align-self="center" cols="auto">
                  <v-radio-group v-model="info_.plan" row>
                    <v-radio label="シンプル プラン" value="シンプル"></v-radio>
                    <v-radio label="月額プラン" value="月額"></v-radio>
                    <v-radio label="定額無制限プラン" value="定額無制限"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="info_.specialTerms"
                    label="特約事項"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="info_.partnerRepName"
                    label="パートナー担当者名"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="info_.paymentMethod" label="支払い方法" readonly />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input
                    v-if="info_.applicationForm == null"
                    v-model="info_.applicationForm"
                    label="申込書（スキャン）の提出"
                    :loading="fileLoading"
                    @change="onFileInputChange"
                  />
                  <v-file-input
                    v-else
                    v-model="info_.applicationForm"
                    label="申込書（スキャン）の提出"
                    :loading="fileLoading"
                    append-outer-icon="mdi-open-in-app"
                    @click:append-outer="onOpenClicked"
                    @change="onFileInputChange"
                  />
                </v-col>
                <v-dialog v-model="applicationDialog">
                  <v-card>
                    <v-card-title>{{info_.applicationForm ? info_.applicationForm.name : ''}}</v-card-title>
                    <v-card-text>
                      <v-img :src="info_.applicationFormURL" />
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapState } from 'vuex'
import { uploadApplicationForm } from '../services/storage'

export default {
  name: 'user-dialog',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    existingEmails: {
      type: Array,
      required: true
    },
    info: {
      type: Object,
      required: false,
      default: function () {
        return {
          shopNo: '',
          customerNo: '',
          creationDate: '',
          postalCode: '',
          address: '',
          customerCompanyName: '',
          customerCompanyRepName: '',
          customerRepDeptName: '',
          customerRepName: '',
          customerTelNo: '',
          customerEmail: '',
          customerPassword: '',
          contractPeriod: '',
          plan: 'シンプル',
          specialTerms: '',
          partnerRepName: '',
          paymentMethod: '請求書',
          applicationForm: null,
          applicationFormId: '',
          applicationFormURL: ''
        }
      }
    }
  },
  watch: {
    info: function (val, old) {
      this.info_ = JSON.parse(JSON.stringify(this.info))
      this.info_.applicationForm = this.info.applicationForm // pass File object instead
    }
  },
  computed: {
    ...mapState(['uid']),
    isEdit () {
      return this.mode === 'edit'
    },
    passwordRequired () {
      return this.mode === 'create'
    }
  },
  data: () => ({
    emptyInfo: {
      shopNo: '',
      customerNo: '',
      creationDate: '',
      postalCode: '',
      address: '',
      customerCompanyName: '',
      customerCompanyRepName: '',
      customerRepDeptName: '',
      customerRepName: '',
      customerTelNo: '',
      customerEmail: '',
      customerPassword: '',
      contractPeriod: '',
      plan: 'シンプル',
      specialTerms: '',
      partnerRepName: '',
      paymentMethod: '請求書',
      applicationForm: null,
      applicationFormId: '',
      applicationFormURL: ''
    },
    info_: {
      shopNo: '',
      customerNo: '',
      creationDate: '',
      postalCode: '',
      address: '',
      customerCompanyName: '',
      customerCompanyRepName: '',
      customerRepDeptName: '',
      customerRepName: '',
      customerTelNo: '',
      customerEmail: '',
      customerPassword: '',
      contractPeriod: '',
      plan: 'シンプル',
      specialTerms: '',
      partnerRepName: '',
      paymentMethod: '請求書',
      applicationForm: null,
      applicationFormId: '',
      applicationFormURL: ''
    },
    creationDateDialog: false,
    showEye: false,
    applicationDialog: false,
    fileLoading: false
  }),
  methods: {
    clone (object) {
      return JSON.parse(JSON.stringify(object))
    },
    close () {
      this.$emit('close')
      this.info_ = this.clone(this.emptyInfo)
    },
    save () {
      const cloned = this.clone(this.info_)
      cloned.applicationForm = this.info_.applicationForm // return File object
      this.$emit('save', cloned)
      this.info_ = this.clone(this.emptyInfo)
    },
    async onFileInputChange () {
      this.fileLoading = true
      const { applicationFormId, applicationFormURL } = await uploadApplicationForm(this.uid, this.info_.applicationForm)
      this.info_.applicationFormId = applicationFormId
      this.info_.applicationFormURL = applicationFormURL
      this.fileLoading = false
    },
    onOpenClicked () {
      this.applicationDialog = true
    }
  },
  destroyed () {
    this.info_ = this.clone(this.emptyInfo)
  }
}
</script>
