import { firebaseStorage } from '@/services/firebase'
import { adminNewId } from '@/services/functions'

export const uploadApplicationForm = async (uid, file) => {
  const { name } = file
  const { data: applicationFormId } = await adminNewId()
  const storageRef = firebaseStorage.ref()
  const applicationFormRef = storageRef.child(`application_forms/${uid}/${applicationFormId}/${name}`)
  await applicationFormRef.put(file)
  const applicationFormURL = await applicationFormRef.getDownloadURL()
  return { applicationFormId, applicationFormURL }
}
