<template>
  <div>
    <users :model="users" :isLoading="usersLoading" @createUser="createUser" @editUser="editUser" />
    <user-dialog
      :dialog="createUserDialog"
      mode="create"
      title="顧客の作成"
      :existingEmails="existingEmails"
      @save="createUserSave"
      @close="createUserClose"
    />
    <user-dialog
      :dialog="editUserDialog"
      mode="edit"
      title="顧客情報の編集"
      :existingEmails="existingEmails"
      :info="info"
      @save="editUserSave"
      @close="editUserClose"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { adminGetAuthUsers, adminGetUsers, adminCreateUser, adminUpdateUser } from '@/services/functions'
import Users from '@/components/Users'
import UserDialog from '@/components/UserDialog'

export default {
  components: {
    Users,
    UserDialog
  },
  computed: {
    ...mapState(['admin'])
  },
  data: () => ({
    users: [],
    usersLoading: false,
    info: {
      shopNo: '',
      customerNo: '',
      creationDate: '',
      postalCode: '',
      address: '',
      customerCompanyName: '',
      customerCompanyRepName: '',
      customerRepDeptName: '',
      customerRepName: '',
      customerTelNo: '',
      customerEmail: '',
      customerPassword: '',
      contractPeriod: '',
      plan: 'シンプル',
      specialTerms: '',
      partnerRepName: '',
      paymentMethod: '請求書',
      applicationForm: null,
      applicationFormId: '',
      applicationFormURL: '',
      userId: ''
    },
    existingEmails: [],
    createUserDialog: false,
    editUserDialog: false
  }),
  methods: {
    plan2Text (plan) {
      if (plan === process.env.VUE_APP_STRIPE_PLAN_FREE) return 'シンプル'
      else if (plan === process.env.VUE_APP_STRIPE_PLAN_5K) return '定額'
      else return '定額無制限'
    },
    text2Plan (text) {
      switch (text) {
        case 'シンプル':
          return process.env.VUE_APP_STRIPE_PLAN_FREE
        case '月額':
          return process.env.VUE_APP_STRIPE_PLAN_5K
        case '定額無制限':
          return process.env.VUE_APP_STRIPE_PLAN_30K
        default:
          return text
      }
    },
    async getUsers (company) {
      const { data } = await adminGetUsers(company)
      const users = data.map(user => {
        if (user.info) {
          user.info.applicationForm = new File([], user.info.applicationForm)
          user.info.plan = this.plan2Text(user.info.plan)
        }
        return user
      })
      return users
    },
    transform (info) {
      const info_ = {
        ...info,
        applicationForm: info.applicationForm ? info.applicationForm.name : '',
        plan: this.text2Plan(info.plan)
      }
      return info_
    },
    async createUser () {
      const { data: { users: authUsers } } = await adminGetAuthUsers()

      console.log({ authUsers })

      this.existingEmails = authUsers.map(user => user.email)
      this.createUserDialog = true
    },
    async editUser (user) {
      this.info = JSON.parse(JSON.stringify(user.info))
      this.info.applicationForm = user.info.applicationForm // Pass File object instead
      const { data: { users: authUsers } } = await adminGetAuthUsers()
      this.existingEmails = authUsers.map(user => user.email).filter(email => email !== this.info.customerEmail)
      this.editUserDialog = true
    },
    async createUserSave (info) {
      this.createUserDialog = false

      this.usersLoading = true
      await adminCreateUser({ company: this.admin.company, info: this.transform(info) })
      this.users = await this.getUsers(this.admin.company)
      this.usersLoading = false
    },
    async editUserSave (info) {
      this.editUserDialog = false

      this.usersLoading = true
      await adminUpdateUser(this.transform(info))
      this.users = await this.getUsers(this.admin.company)
      this.usersLoading = false
    },
    createUserClose () {
      this.createUserDialog = false
    },
    editUserClose () {
      this.editUserDialog = false
    }
  },
  async mounted () {
    this.usersLoading = true
    this.users = await this.getUsers(this.admin.company)
    this.usersLoading = false
  }
}
</script>
